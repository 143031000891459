import { SVG_SIZES } from '@utils/constants';
import styles from './css';

type IconProps = {
  color?: 'blue' | 'gray';
  size?: 'lg' | 'md';
};

const IconFaq = ({ color = 'gray', size = 'md' }: IconProps) => {
  const className = `color-${color}`;
  const height = SVG_SIZES[size];
  const width = SVG_SIZES[size];

  return (
    <>
      <svg
        className={className}
        fill="none"
        height={height}
        viewBox="0 0 25 24"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="15" cy="14.5" fill="currentColor" opacity="0.16" r="9.5" />
        <path
          d="M12.5 22C18.0229 22 22.5 17.5229 22.5 12C22.5 6.47715 18.0229 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5229 6.97715 22 12.5 22Z"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M12.5 14.3125V12.3125C14.1568 12.3125 15.5 10.9694 15.5 9.3125C15.5 7.65565 14.1568 6.3125 12.5 6.3125C10.8432 6.3125 9.5 7.65565 9.5 9.3125"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          clipRule="evenodd"
          d="M12.5 18.8125C13.1904 18.8125 13.75 18.2529 13.75 17.5625C13.75 16.8722 13.1904 16.3125 12.5 16.3125C11.8097 16.3125 11.25 16.8722 11.25 17.5625C11.25 18.2529 11.8097 18.8125 12.5 18.8125Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
      <style jsx>{styles}</style>
    </>
  );
};

export default IconFaq;
