import useTranslation from 'next-translate/useTranslation';
import BusinessManAvatar from '@assets/business-man-avatar.svg';
import DesignerAvatar from '@assets/designer-avatar.svg';
import FreelancerAvatar from '@assets/freelancer-avatar.svg';
import Button from '@atoms/Button';
import { TypoSBold, TypoXSBold, TypoXXS } from '@atoms/Typos';
import UseCaseCard from '@molecules/UseCaseCard';
import routes from '@utils/routes';

import styles from './css';

const testimonialsDialogAttr = [
  {
    avatar: <BusinessManAvatar />,
    planCode: 'standard',
    useCasePropertyKeys: ['plat-hostel-keikyu-haneda-home', 'webase-kamakura'],
  },
  {
    avatar: <DesignerAvatar />,
    planCode: 'premium',
    useCasePropertyKeys: [
      'hotel-it-osaka-shinmachi',
      'kefu-stay-lounge',
      'hafh-fukuoka-the-life',
      'hotel-anteroom-naha',
    ],
  },
  {
    avatar: <FreelancerAvatar />,
    planCode: 'premium_plus',
    useCasePropertyKeys: [
      'the-millennials-shibuya',
      'hafh-nagasaki-sai',
      'i+land-nagasaki',
    ],
  },
];

type Props = {
  planCode: null | string;
};

const NeighborTestimonialDialogContent = ({ planCode }: Props) => {
  const { t } = useTranslation('testimonial');

  const testimonialDialogAttr = testimonialsDialogAttr.find(
    (attr) => attr.planCode === planCode
  );
  const { avatar, useCasePropertyKeys } = testimonialDialogAttr;

  return (
    <div className="neighbor-testimonial-dialog-content-cont">
      <div className="neighbor-testimonial-dialog-header-cont">
        {avatar}
        <div className="neighbor-testimonial-dialog-header-wrap">
          <h3>
            <TypoSBold text={t(`testimonial.${planCode}.plan`)} />
          </h3>
          <p>
            <TypoXXS text={t(`testimonial.${planCode}.neighborInfo`)} />
          </p>
        </div>
      </div>
      <div className="neighbor-testimonial-dialog-use-case-cont">
        <TypoSBold text={t(`testimonial.${planCode}.title`)} />
        <div className="neighbor-testimonial-dialog-use-case-list">
          {useCasePropertyKeys.map((useCasePropertyKey, index) => (
            <UseCaseCard
              dayTitle={t(
                `testimonial.${planCode}.useCase.dayTitle${index + 1}`
              )}
              description={t(
                `testimonial.${planCode}.useCase.description${index + 1}`
              )}
              key={useCasePropertyKey}
              propertyImgKey={useCasePropertyKey}
              propertyName={t(
                `testimonial.${planCode}.useCase.propertyName${index + 1}`
              )}
              t={t}
            />
          ))}
        </div>
      </div>
      <div className="dialog-call-to-action-cont">
        <div className="call-to-action-text">
          <TypoXSBold text={t('doYouWantToStart')} />
        </div>
        <Button label={t('createAccount')} link={routes.signup} />
      </div>

      <style jsx>{styles}</style>
    </div>
  );
};

export default NeighborTestimonialDialogContent;
