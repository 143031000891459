import { useState } from 'react';
import { useRouter } from 'next/router';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import IconBag from '@assets/icon-bag-with-coin.svg';
import Dialog from '@atoms/Dialog';
import { TypoXS, TypoXSBold } from '@atoms/Typos';
import useRegistrationCountry from '@hooks/useRegistrationCountry';
import IconCoin from '@svg-icons/IconCoin';
import IconFaq from '@svg-icons/IconFaq';
import routes from '@utils/routes';
import styles from './css';

const icons = [
  {
    icon: <IconCoin color="gray" size="lg" />,
    key: 'coin',
  },
  {
    icon: <IconBag />,
    key: 'bag',
  },
];

const HafhCoinDialogButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { t } = useTranslation('common');
  const { defaultLocale, locale } = useRouter();
  const { isRegistrationCountryJapan } = useRegistrationCountry();

  const onClickButton = () => {
    const localePath = locale === defaultLocale ? '' : `/${locale}`;
    window.open(`${localePath}${routes.coin}`, '_blank');
  };

  return (
    <div className="coin-dialog-wrapper">
      <button
        className="coin-dialog-button"
        type="button"
        onClick={() => setShowDialog(true)}
      >
        <TypoXSBold text={t('hafhCoinDialog.aboutHafhCoin')} />
        <IconFaq color="blue" />
      </button>

      <Dialog
        headerText={t('hafhCoinDialog.aboutHafhCoin')}
        primaryButtonLabel={t('viewMore')}
        show={showDialog}
        onClickButton={() => setShowDialog(false)}
        onClickPrimaryButton={onClickButton}
      >
        <div className="coin-dialog-container">
          <ul className="coin-dialog-list">
            {icons.map((attr, index) => {
              if (
                ['calendar', 'exchange'].includes(attr.key) &&
                !isRegistrationCountryJapan
              ) {
                return null;
              }
              return (
                <li className="coin-dialog-item" key={attr.key}>
                  <div className="coin-dialog-icon">{attr.icon}</div>
                  <div className="coin-dialog-text">
                    <TypoXS>
                      <Trans
                        components={{
                          bold: <TypoXSBold />,
                        }}
                        i18nKey={`common:hafhCoinDialog.content${index + 1}`}
                      />
                    </TypoXS>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Dialog>

      <style jsx>{styles}</style>
    </div>
  );
};

export default HafhCoinDialogButton;
