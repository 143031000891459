import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .coin-dialog-wrapper {
    .coin-dialog-button {
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      :global(.typo-bold) {
        margin-right: 4px;
        color: ${COLORS.primary};
        display: inline;
      }
    }
    .coin-dialog-container {
      padding: 16px;
      .coin-dialog-item {
        display: flex;
        align-items: center;
        margin-top: 24px;
        &:first-child {
          margin-top: 0;
        }
        .coin-dialog-icon {
          margin-right: 16px;
        }
        .coin-dialog-text {
          text-align: left;
          :global(.typo-xs) {
            display: inline;
            color: ${COLORS.black700};
          }
        }
      }
    }
  }
`;

export default styles;
