import { global } from 'styled-jsx/css';
import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const { mobile } = BREAKPOINT;

export default global`
  .swiper-arrow-svg {
    height: 24px;
    width: 24px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    float: left;
    width: 40px;
    height: 40px;
    z-index: 10;
    cursor: pointer;
    background-size: 27px 44px;
    background-position: center;
    background-repeat: no-repeat;
    background: ${COLORS.white};
    border-radius: 50%;
    display: block;
    @media screen and (max-width: ${mobile}px) {
      display: none;
    }
  }
`;
