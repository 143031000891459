import { ReactNode, useState } from 'react';
import SwiperCore from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper as SwiperLibrary } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';
import IconArrow from '@assets/icon-arrow-current-unsized.svg';
import { TypoXXS } from '@atoms/Typos';
import styles from './css';

type Props = {
  children: ReactNode[];
  className?: string;
  hasDynamicBullets?: boolean;
  hasIndex?: boolean;
  hasPagination?: boolean;
  hasSwiperButton?: boolean;
  name: string;
  options?: SwiperOptions;
};

const SwiperArrow = ({ rotate = '' }) => (
  <div className="absolute inset-0 flex items-center justify-center z-10">
    <IconArrow className={`${rotate} swiper-arrow-svg`} />
  </div>
);

const Swiper = ({
  children,
  hasDynamicBullets = false,
  hasIndex = false,
  hasPagination,
  hasSwiperButton = true,
  name,
  options = {},
}: Props) => {
  const [index, setIndex] = useState(1);
  const features = [];

  if (hasPagination) {
    features.push(Pagination);
  }

  if (hasSwiperButton) {
    features.push(Navigation);
  }

  if (options.autoplay) {
    features.push(Autoplay);
  }

  if (features.length > 0) {
    SwiperCore.use(features);
  }

  return (
    <>
      <SwiperLibrary
        className={`swiper-${name}`}
        effect="slide"
        navigation={
          hasSwiperButton
            ? {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }
            : false
        }
        pagination={
          hasPagination
            ? {
                clickable: true,
                dynamicBullets: hasDynamicBullets,
                el: '.swiper-pagination',
              }
            : false
        }
        onActiveIndexChange={(s) => setIndex(s.activeIndex + 1)}
        {...options}
      >
        {children}
        {hasSwiperButton && (
          <>
            <div
              className="swiper-button-prev"
              role="button"
              tabIndex={0}
              onClick={(_event) => _event.stopPropagation()}
              onDoubleClick={(_event) => _event.stopPropagation()}
              onKeyDown={(_event) => {
                _event.stopPropagation();
              }}
            >
              <SwiperArrow rotate="rotate-180" />
            </div>
            <div
              className="swiper-button-next"
              role="button"
              tabIndex={0}
              onClick={(_event) => _event.stopPropagation()}
              onDoubleClick={(_event) => _event.stopPropagation()}
              onKeyDown={(_event) => {
                _event.stopPropagation();
              }}
            >
              <SwiperArrow />
            </div>
          </>
        )}
        {hasPagination && <div className="swiper-pagination" />}
        {hasIndex && (
          <span className="swiper-index">
            {index}
            /
            <TypoXXS color="white" text={children?.length} />
          </span>
        )}
      </SwiperLibrary>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default Swiper;
