import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .use-case-card-cont {
    display: grid;
    grid-template-columns: 130px 1fr;
    gap: 16px;
    white-space: pre-wrap;
    .use-case-card-image {
      border-radius: 12px;
      overflow: hidden;
      width: 130px;
      height: 86px;
      position: relative;
    }
    .use-case-card-heading {
      font-size: 20px;
      font-weight: bold;
      color: ${COLORS.primary};
    }
  }
`;

export default styles;
